import IndeterminateLoadingSpinner from '@/components/IndeterminateLoadingSpinner/IntedeterminateLoadingSpinner';
import { InlineLoadError } from '@/components/InlineLoadError';
import SessionLayout from '@/components/Layouts/SessionLayout';
import { LoginOrSignUp } from '@/components/LoginOrSignUp';
import Wrapper from '@/containers/Wrapper';
import { useEndSessionMutation, useGetCurrentUserQuery } from '@/lib/auth/api';
import OneSignalTokenManager from '@/lib/onesignal/OneSignalTokenManager';
import LoginOrSignUpBrand from '@/lib/session/components/LoginOrSignUpBrand';
import useLoginRedirectUrl from '@/lib/session/hooks/useLoginRedirectUrl';
import useSaveLoginRedirectUrl from '@/lib/session/hooks/useSaveLoginRedirectUrl';
import { getCookie } from 'cookies-next';
import Head from 'next/head';
import { useRouter } from 'next/router';
import React, { ReactElement } from 'react';
import toast from 'react-hot-toast';

// export const getServerSideProps = wrapper.getServerSideProps(
//   store => async context => {
//     // Store the cookie in session so our queries can access it in SSR
//     store.dispatch(setCookie(context.req.headers.cookie ?? null));

//     // Get the current user
//     store.dispatch(getCurrentUser.initiate());

//     await delay(5000);

//     // Wait for all the requests to finish
//     await Promise.all(getRunningOperationPromises());

//     return {
//       props: {
//         key: 'session',
//         data: {
//           minimalFooter: true,
//         },
//       },
//     };
//   },
// );

function Session() {
  const router = useRouter();
  const redirectUrl = useLoginRedirectUrl();
  const referrer = getCookie('_greener_r');
  const isBrandSignUp = referrer === 'origin' || referrer === 'tyro';

  useSaveLoginRedirectUrl();

  const { data: user, isLoading, isError, refetch } = useGetCurrentUserQuery();
  // const [refreshSession] = useRefreshSessionMutation();
  const [signOut] = useEndSessionMutation();

  const onLoginAsCurrentUser = async () => {
    if (!user) return;

    try {
      // Tell OneSignal
      OneSignalTokenManager.shared.identifyUser(user);

      // Get a new session
      // TOM 25/1/2023 - Theres actually no need for this - refresh
      // generates new access/refresh tokens, and the browser has no use for that.
      // const session = await refreshSession().unwrap();

      localStorage.removeItem('redirect');
      router.replace(redirectUrl);
    } catch {
      toast.error('Something went wrong. Please try again');
    }
  };

  // const onSignoutClicked = async () => {
  //   try {
  //     // Get a new session
  //     signOut().unwrap();
  //   } catch {
  //     toast.error('Something went wrong. Please try again');
  //   }
  // };

  React.useEffect(() => {
    if (user) {
      router.replace(`/organisations?redirect=${redirectUrl}`);
      return;
    }

    if (referrer === 'origin' || referrer === 'tyro') {
      router.replace(`/session/${referrer}?redirect=${redirectUrl}`);
    }
  }, [redirectUrl, referrer, router, user]);

  return (
    <div>
      <Head>
        <title>Session</title>
      </Head>
      <Wrapper>
        {isError ? (
          <div className="py-8">
            <InlineLoadError
              isLoading={isLoading}
              isError={isError}
              message="Error loading user data"
              refetch={refetch}
            />
          </div>
        ) : null}
        <IndeterminateLoadingSpinner loading={isLoading} />
      </Wrapper>
      {!isError && !isLoading ? (
        <>
          {user ? (
            <>
              {/* <AlreadyLoggedUser
                user={user}
                onLoginAsCurrentUser={onLoginAsCurrentUser}
                onSignoutClicked={onSignoutClicked}
              /> */}
            </>
          ) : (
            <>
              {!isBrandSignUp ? <LoginOrSignUp /> : null}
              {isBrandSignUp ? <LoginOrSignUpBrand /> : null}
            </>
          )}
        </>
      ) : null}
    </div>
  );
}

Session.getLayout = function getLayout(page: ReactElement) {
  return <SessionLayout>{page}</SessionLayout>;
};

Session.displayName = 'Session';
export default Session;
